body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.LandIcons {
  font-size: 100px !important;
  color: #e56300;
  background: white;
}

.HomePageLanding {
  background-image: url("./House/Assets/Images/Home/HomeLanding.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.HomePageLandingWeb {
  background-image: url("./House/Assets/Images/WebDesign/WebLanding.jpg");
  background-position: center;
  background-size: cover;
}

.HomePageLandingGraphic {
  background-image: url("./House/Assets/Images/Graphic/GraphicLanding.jpg  ");
  background-size: cover;
  background-position: center;

}

.HomePageLandingSocial{
  background-image: url("./House/Assets/Images/Social/SocialLanding.jpg ");
  background-size: cover;
  background-position: center;

}

.HomePageLandingSeo{
  background-image: url("./House/Assets/Images/SEO/SeoLanding.jpg ");
  background-size: cover;
  background-position: center;

}

.HomePageLandingAbout{
  background-image: url("./House/Assets/Images/Contact/AboutLanding.jpg ");
  background-size: cover;
  background-position: center;

}

.HomePageLandingContact{
  background-image: url("./House/Assets/Images/Contact/ContactLanding.jpg");
  background-size: cover;
  background-position: center;

}


.Back2 {
  background-image: url("./House/Assets/Images/Home/Back2.jpg");
  background-position: right;
  background-size: cover;
}

.BackBlocksWeb {
  background-image: url("./House/Assets/Images/WebDesign/WebBox.jpg");
  background-position: top center;
  background-size: cover;
}

.BackBlocksGraphic {
  background-image: url("./House/Assets/Images/Graphic/BackLadyWeb.jpg  ");
  background-position:top center;
  background-size: cover;
}

.BackBlocksSocial {
  background-image: url("./House/Assets/Images/Social/SocialBox.jpg");
  background-position:top center;
  background-size: cover;
}

.BackBlocksSeo {
  background-image: url("./House/Assets/Images/SEO/SeoBox.jpg");
  background-position:top center;
  background-size: cover;
}


.shader {
  background-color: #33333380;
}

.shader1 {
  background-color: #33333321;
}

.primary {
  color: #333333;
}

.secondary {
  color: #e56300;
}

.Secondary{
  background-color: #e56300;
}

.Primary {
  background-color: #333333;
  transition: all ease-in-out 0.3s;
}

.PrimaryB {
  background-color: #333333;
  transition: all ease-in-out 0.3s;
}

.Primary:hover {
  background-color: #e56300;
  transition: all ease-in-out 0.3s;
}

.Whitext {
  color: white;
}

.Whitext-F {
  color: rgba(223, 223, 223, 0.899);
}

.Whitext-F:hover {
  color: #e56300;
}

.darkshadow {
  box-shadow: #33333319 0px 0px 20px -5px;
  transition: all ease-in-out 0.3s;
}



.Whiteshadow {
  box-shadow: #ffffff30 0px 0px 20px -5px;
  transition: all ease-in-out 0.3s;
}

.darkshadow:hover {
  box-shadow: #ffffff63 0px 0px 20px -5px;
  transition: all ease-in-out 0.3s;
}

.shadower {
  transition: all ease-in-out 0.3s;
  box-shadow: #000000 inset 0px 0px 20px -5px;
}

.shadower:hover {
  box-shadow: #f2f2f2c4  0px 0px 20px -5px;
  transition: all ease-in-out 0.3s;
}

.Transition {
  transition: all ease-in-out 0.7s;
}
.Transition:hover {
  transition: all ease-in-out 0.7s;
}

.Transition-Fast {
  transition: all ease-in-out 0.3s;
}

.YellowBorder {
  border: #e56300 solid 1px;
  border-radius: 20px;
  transition: all ease-in-out 0.3s;
}

.YellowBorder:hover {
  border: #fff solid 1px;
  transition: all ease-in-out 0.3s;
}

.White-Border {
  border: #fff solid 1px;
}

.PicSpacerBG {
  background-image: url("./House/Assets/Images/Home/Brigde.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.Border-B {
  border-bottom: #e56300 solid 3px;
}

.MenuIcon {
  color: white;
  font-size: 34px !important;
}

.ZoomControl {
  overflow: hidden;
}

.Zoomer {
  transition: transform 0.5s ease-in-out;
}
.Zoomer:hover {
  transform: scale(2) rotate(35deg);
  transition: transform 0.5s ease-in-out;
}

.ZoomerText {
  transition: transform 0.5s ease-in-out;
}

.ZoomerText:hover {
  width: 100%;
  height: 100%;
  background-color: #333333;
  transition: transform 0.5s ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
